'use strict';
var Swiper = require('swiper');
module.exports = function () {
    $(document).on('livecampaign:loaded', function() {
        var featuredSection = new Swiper.default('.featured-swiper-init', {
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 30,
            slidesOffsetAfter: 15,
            preloadImages: false,
            watchSlidesVisibility: true,
            freeMode: true,
            // Enable lazy loading,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    spaceBetween: 30,
                },
                // when window width is >= 768
                768: {
                    spaceBetween: 16,
                },
            },
            navigation: {
                nextEl: ".featured-container .swiper-button-next",
                prevEl: ".featured-container .swiper-button-prev",
            },
        });
    });
};
