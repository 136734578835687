/* eslint-disable no-undef */
'use strict';
var base = require('base/product/base');
var isMobile = !($(window).width() >= 768);
var toastr = require('toastr');
// const braze = require('../braze');
var lottie = require('lottie-web');

var $animate = 1000;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg +
            '</div>';
    });

    return html;
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id
            + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.prize;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when updating a product in a cart
 *
 * @return {string} - The provided URL to use when updating a product in a cart
 */
function getUpdateToCartUrl() {
    return $('.add-to-cart-url').data('action-update');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {string} shouldRefresh - data object to find if page need to be reloaded
 */
function handlePostCartAdd(response, shouldRefresh) {
    $('.minicart').trigger('count:update', response);
    var itemInCart = typeof response.numItems !== 'undefined' ? response.numItems : typeof response.basket !== 'undefined' ? response.basket.numItems : response.cart.numItems;
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        $('.icon-cart').removeClass('empty');
        if (itemInCart > 0) {
            $('.newCart-indicator .cart-indicator-icon span').addClass('show');
        } else {
            $('.newCart-indicator .cart-indicator-icon span').removeClass('show');
        }
        $('.newCart-indicator .cart-indicator-icon span').text(itemInCart);

        $('.newCart-indicator').addClass('animate');
        setTimeout(function () {
            $('.newCart-indicator').removeClass('animate');
        }, $animate);

        // if (shouldRefresh) {
        //     setTimeout(function () {
        //         location.reload();
        //     }, 1000);
        // }

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * update current wish list item in basket
 * @param {*} $container element
 * @param {*} $items cart items
 * @param {*} $pid current wishlist item
 */
function handleWishlist($container, $items, $pid) {
    if ($container.closest('.wishlist-item').length) {
        $items.forEach(function (item) {
            if (item.id === $pid) {
                $container.attr('data-quantity', item.quantity);
                if ($container.attr('data-lineitem-uuid') === 'null') {
                    $container.attr('data-lineitem-uuid', item.UUID);
                }
            }
        });
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
* @param {jQuery} $container - DOM container for a given product
* @param {number} quantity - quantity for each childproduct
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts($container, quantity) {
    var childProducts = [];
    $container.closest('.product-detail').find('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).data('pid'),
            quantity: quantity
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Add loader on cart
 * @param {*} trig element
 */
function addAddToCartLoader(trig) {
    var $loader = trig.data('loader');
    trig.addClass('loading');
    trig.html('<div id="addToCartLoader"></div>');
    lottie.loadAnimation({
        container: document.getElementById('addToCartLoader'), // the dom element that will contain the animation
        renderer: 'svg',
        name: 'addtocart',
        loop: true,
        autoplay: true,
        path: $loader // the path to the animation json
    });
}

/**
 * Remove loader on cart
 * @param {*} trig element
 */
function removeAddToCartLoader(trig) {
    var $text = trig.data('text');
    setTimeout(function () {
        trig.removeClass('loading');
        trig.html($text);
        lottie.destroy('addtocart');
    }, 500);
}
/**
 * Show Animation on bundle added to cart
 */
function bundleAddedAnimation() {
    $('.original-text').css('display', 'none');
    $('.bundle-added-text').fadeIn();
    $('.bundle-container').addClass('show-animation');
    $('.js-add-bundle-to-cart').addClass('added-to-cart');
    setTimeout(function () {
        $('.bundle-added-text').css('display', 'none');
        $('.original-text').fadeIn();
        $('.bundle-container').removeClass('show-animation');
        $('.js-add-bundle-to-cart').removeClass('added-to-cart');
    }, 2000);
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: base.focusChooseBonusProductModal,

    onClosingChooseBonusProductModal: base.onClosingChooseBonusProductModal,

    trapChooseBonusProductModalFocus: base.trapChooseBonusProductModalFocus,

    colorAttribute: base.colorAttribute,

    selectAttribute: base.selectAttribute,

    availability: base.availability,

    addToCart: function () {
        $(document)
            .off('click', 'button.add-to-cart, button.add-to-cart-global, .miniCartWrapper .js-plus')
            .on('click', 'button.add-to-cart, button.add-to-cart-global, .miniCartWrapper .js-plus', function () {
                var addToCartUrl;
                var pid;
                var quantity;
                var pidsObj;
                var setPids;
                var $container = $(this);
                var maxQuantity = $(this).closest('.new-quantity-dropper-wrapper').find('.cart-quantity').attr('max');
                maxQuantity = parseInt(maxQuantity);

                $('body').trigger('product:beforeAddToCart', this);
                var page = 'home';
                if ($('.page').attr('data-action') === 'Home-Show') {
                    page = 'Home';
                } else if ($('.page').attr('data-action') === 'Product-Show') {
                    page = 'L2';
                } else if ($('.page').attr('data-action') === 'Cart-Show') {
                    page = 'Cart';
                } else if ($('.page').attr('data-action') === 'Wishlist-Show') {
                    page = 'Wishlist';
                }
                addAddToCartLoader($container);
                if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                    setPids = [];

                    $('.product-detail').each(function () {
                        if (!$(this).hasClass('product-set-detail')) {
                            setPids.push({
                                pid: $(this).find('.product-id').text(),
                                qty: $(this).find('.quantity-select').length ? $(this).find('.quantity-select').val() : $(this).closest('#quickViewModal').find('.quantity-select'),
                                options: getOptions($(this))
                            });
                        }
                    });
                    pidsObj = JSON.stringify(setPids);
                } else {
                    // pid = button has data value for pid;
                    pid = $(this).data('pid');
                    var $all = $('.product-detail[data-pid = ' + pid + ']');
                    var $allMinCart = $('.cart-items[data-product-id = ' + pid + ']');
                    // selected quantity

                    if ($(this).closest('.product-detail').length) {
                        quantity = parseInt($(this).closest('.product-detail').find('.cart-quantity').val(), 10);
                    } else if ($(this).closest('.modalBox.show').length) {
                        quantity = parseInt($(this).closest('.modalBox.show').find('.cart-quantity').val(), 10);
                    } else if ($(this).closest('.wishlist-item').length) {
                        quantity = 1;
                    } else if ($(this).closest('.popover.show').length) {
                        quantity = parseInt($(this).closest('.item-block').find('.cart-quantity').val(), 10);
                    } else {
                        quantity = parseInt($('.campaing-detail-footer')
                            .find('.quantity-select')
                            .children('option:selected')
                            .val(), 10);
                    }
                    var $productContainer = $(this).closest('.product-detail');
                    if (!$productContainer.length) {
                        $productContainer = $(this).closest('#quickViewModal').find('.product-detail');
                    }
                    var form;
                    // Add to cart & Update quantity homepage Mobile


                    if ($container.hasClass('added-to-cart')) {
                        var lineitemUuid = $(this).attr('data-lineitem-uuid');

                        quantity = quantity < maxQuantity ? quantity + 1 : maxQuantity.toFixed(0);

                        form = {
                            pid: pid,
                            quantity: isNaN(quantity) ? 1 : quantity,
                            uuid: lineitemUuid,
                            isMobile: isMobile,
                            page: page
                        };
                        if ($container.closest('.wishlist-item').length && $container.attr('data-bundlepid')) {
                            if ($container.attr('data-quantity') && parseInt($container.attr('data-quantity'), 10) > 0) {
                                quantity = parseInt($container.attr('data-quantity'), 10) + 1;
                                form.quantity = quantity;
                            }
                            form.childProducts = JSON.stringify([{
                                pid: $container.attr('data-bundlepid'),
                                quantity: quantity
                            }]);
                        }
                        // if (!$('.bundle-item').length) {
                        //     form.options = getOptions($productContainer);
                        // }
                        addToCartUrl = getUpdateToCartUrl();

                        addToCartUrl = addToCartUrl || $(this).parent().find('.cart-quantity').data('action');
                        addToCartUrl = appendToUrl(addToCartUrl, form);

                        if (addToCartUrl) {
                            $.ajax({
                                url: addToCartUrl,
                                type: 'get',
                                context: this,
                                dataType: 'json',
                                success: function (data) {
                                    if (data.error) {
                                        alert(data.message);
                                    } else {
                                        if (data.error) {
                                            toastr.error(data.message);
                                        } else {
                                            // eslint-disable-next-line no-lonely-if
                                            $all.find('.cart-quantity').val(quantity);
                                            $all.closest('#quickViewModal').find('.cart-quantity').val(quantity);
                                            $allMinCart.find('.cart-quantity').val(quantity);
                                            $('.newCart-indicator .cart-indicator-icon  span').text(data.numItems);
                                            $('.cart-page span.minicart-quantity').text(data.numItems);
                                            handleWishlist($container, data.items, pid);
                                        }

                                        handlePostCartAdd(data, $container.data('refresh'));
                                        try {
                                            var addToCartParam = data.addToCartGtag;
                                            var removeFromCartGtag = data.removeFromCartGtag;
                                            if (addToCartParam) {
                                                $container.attr('data-gtag-add-event', addToCartParam.event);
                                                $container.attr('data-gtag-add-param', addToCartParam.param);
                                                $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                            }

                                            if (removeFromCartGtag) {
                                                $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                            }
                                            // trigger add to cart event for braze
                                            // braze.handleAddRemoveEvent(data.addToCartGtag);
                                        } catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.warn(e.message);
                                        }
                                        $('body').trigger('cart:update', data);
                                        $('body').trigger('cart:addTOCartGTM', $container);
                                    }
                                    removeAddToCartLoader($container);
                                },
                                error: function () {
                                    removeAddToCartLoader($container);
                                }
                            });
                        }
                    } else {
                        // Add to cart homepage Desktop

                        form = {
                            pid: pid,
                            pidsObj: pidsObj,
                            quantity: quantity,
                            childProducts: getChildProducts($container, quantity),
                            isMobile: isMobile,
                            page: page
                        };
                        if ($container.closest('.wishlist-item').length && $container.attr('data-bundlepid')) {
                            form.childProducts = JSON.stringify([{
                                pid: $container.attr('data-bundlepid'),
                                quantity: quantity
                            }]);
                        }
                        // if (!$('.bundle-item').length) {
                        //     form.options = getOptions($productContainer);
                        // }
                        addToCartUrl = getAddToCartUrl();
                        $container.addClass('added-to-cart');


                        $(this).trigger('updateAddToCartFormData', form);

                        if (addToCartUrl) {
                            $.ajax({
                                url: addToCartUrl,
                                method: 'POST',
                                data: form,
                                success: function (data) {
                                    if (data.error) {
                                        toastr.error(data.message);
                                    } else {
                                        // eslint-disable-next-line no-lonely-if
                                        module.exports.addToCartUiMobile($container, data, pid);
                                        handleWishlist($container, data.cart.items, pid);
                                    }

                                    handlePostCartAdd(data, $container.data('refresh'));
                                    try {
                                        var addToCartParam = data.addToCartGtag;
                                        var removeFromCartGtag = data.removeFromCartGtag;
                                        if (addToCartParam) {
                                            $container.attr('data-gtag-add-event', addToCartParam.event);
                                            $container.attr('data-gtag-add-param', addToCartParam.param);
                                            $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                        }

                                        if (removeFromCartGtag) {
                                            $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                        }
                                        // trigger add to cart event for braze
                                        // braze.handleAddRemoveEvent(data.addToCartGtag);
                                    } catch (e) {
                                        // eslint-disable-next-line no-console
                                        console.warn(e.message);
                                    }
                                    removeAddToCartLoader($container);
                                    $('body').trigger('product:afterAddToCart', data);
                                    $('body').trigger('cart:addTOCartGTM', $container);
                                    $('body').trigger('product:AddedToCartFromDetail');
                                },
                                error: function () {
                                    removeAddToCartLoader($container);
                                }
                            });
                        }
                    }
                }
            });
    },

    addToCartHomepageMobile: function () {
        $(document)
            .off('click', '.js-new-add-to-cart-btn')
            .on('click', '.js-new-add-to-cart-btn', function () {
                if ($(this).attr('disabled') === 'disabled') {
                    return;
                }
                $(this).attr('disabled', true);
                var $productContainer = $(this).closest('.product-detail');
                var addToCartUrl = getAddToCartUrl();
                var isItemInCart = $productContainer.find('.add-to-cart-area').hasClass('in-cart');
                var pid = $(this).data('pid');
                var quantity = parseInt($productContainer.find('.cart-quantity').val(), 10);
                var uuid = $productContainer.attr('data-lineitem-uuid');
                var pidsObj;
                var $container = $(this);
                var maxQuantity = $(this).closest('.new-quantity-dropper-wrapper').find('.cart-quantity').attr('max');
                maxQuantity = parseInt(maxQuantity);
                var page = 'home';
                $('body').removeClass('scroll-lock');
                var form;
                $('body').trigger('product:beforeAddToCart', this);
                if ($(this).hasClass('js-add-all-multipleEntry')) {
                    quantity = parseInt($(this).attr('data-multipleentry'));
                    $(this).closest('.new-quantity-dropper-wrapper').find('.js-dynamically-created li:nth-child(' + quantity + ')').addClass('active')
                        .siblings()
                        .removeClass('active');
                } else {
                    addAddToCartLoader($container);
                }
                form = {
                    pid: pid,
                    pidsObj: pidsObj,
                    quantity: quantity,
                    childProducts: getChildProducts($container, quantity),
                    isMobile: isMobile,
                    page: page
                };

                $(this).trigger('updateAddToCartFormData', form);
                $('.js-qty-text').attr('disabled', true);

                if (isItemInCart) {
                    $.spinner().start();
                    addToCartUrl = getUpdateToCartUrl();
                    form.uuid = uuid;

                    addToCartUrl = appendToUrl(addToCartUrl, form);

                    // $(this).trigger('updateAddToCartFormData', form);

                    $.ajax({
                        url: addToCartUrl,
                        type: 'get',
                        context: this,
                        dataType: 'json',
                        success: function (data) {
                            if (data.error) {
                                toastr.remove();
                                toastr.error(data.message);
                            } else {
                                removeAddToCartLoader($container);
                                // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                                // handlePostCartAdd(data, $container.data('refresh'));
                                try {
                                    var addToCartParam = data.addToCartGtag;
                                    var removeFromCartGtag = data.removeFromCartGtag;
                                    if (addToCartParam) {
                                        $container.attr('data-gtag-add-event', addToCartParam.event);
                                        $container.attr('data-gtag-add-param', addToCartParam.param);
                                        $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    }

                                    if (removeFromCartGtag) {
                                        $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    }
                                    $productContainer.removeClass('qtyActive');
                                    // trigger add to cart event for braze
                                    // braze.handleAddRemoveEvent(data.addToCartGtag);
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.warn(e.message);
                                }
                                $('body').trigger('cart:update', data);
                                $('body').trigger('cart:addTOCartGTM', $container);
                            }
                            $.spinner().stop();
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                } else if (addToCartUrl) {
                    $.spinner().start();
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if (data.error) {
                                toastr.remove();
                                toastr.error(data.message);
                                $('.js-qty-text').attr('disabled', false);
                                $('.js-new-add-to-cart-btn').attr('disabled', false);
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                            }


                            // handlePostCartAdd(data, $container.data('refresh'));
                            try {
                                var addToCartParam = data.addToCartGtag;
                                var removeFromCartGtag = data.removeFromCartGtag;
                                if (addToCartParam) {
                                    $container.attr('data-gtag-add-event', addToCartParam.event);
                                    $container.attr('data-gtag-add-param', addToCartParam.param);
                                    $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                }

                                if (removeFromCartGtag) {
                                    $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                }
                                // trigger add to cart event for braze
                                // braze.handleAddRemoveEvent(data.addToCartGtag);
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.warn(e.message);
                            }

                            removeAddToCartLoader($container);
                            $.spinner().stop();
                            $productContainer.removeClass('qtyActive');
                            $productContainer.find('.new-quantity-dropper ul li.active').removeClass('active');
                            $productContainer.find('.new-quantity-dropper ul li:first-child').addClass('active');
                            $(this).closest('.product-detail').find('.cart-quantity').val('1');
                            $('body').trigger('product:afterAddToCart', data);
                            $('body').trigger('cart:addTOCartGTM', $container);
                            $('body').trigger('product:AddedToCartFromDetail');
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                }
            });
    },

    addToCartClosingSoon: function () {
        $(document)
            .off('click', '.closing-add-to-cart')
            .on('click', '.closing-add-to-cart', function (e) {
                e.preventDefault();
                var addToCartUrl = $(this).data('add-to-cart-url');
                var updateToCartUrl = $(this).data('update-cart-url');
                var $container = $(this).parents('.closing-soon__item_parent');
                var pid = $($container).find('.closing-soon__item').data('product-id');
                var uuid = $($container).find('.closing-soon__item').attr('data-lineitem-uuid');
                var quantity = parseInt($($container).find('.qtyIcon').text());
                var maxQuantity = $(this).attr('max');
                maxQuantity = parseInt(maxQuantity);
                $.spinner().start();

                if (isMobile) {
                    quantity = quantity < maxQuantity ? quantity + 1 : maxQuantity.toFixed(0);
                } else {
                    quantity = quantity < maxQuantity ? quantity + 1 : maxQuantity.toFixed(0);
                }
                if (isNaN(quantity)) {
                    quantity = 1;
                }

                if (quantity > 1) {
                    addToCartUrl = updateToCartUrl;
                }
                var page = 'home';
                var form;
                // addAddToCartLoader($container);
                var childProducts = [];
                $container.find('.product-detail').find('.bundle-item').each(function () {
                    childProducts.push({
                        pid: $(this).data('pid'),
                        quantity: quantity
                    });
                });

                childProducts = childProducts.length ? JSON.stringify(childProducts) : [];
                form = {
                    pid: pid,
                    quantity: quantity,
                    childProducts: childProducts,
                    isMobile: isMobile,
                    page: page
                };
                if (quantity > 1) {
                    form.uuid = uuid;

                    addToCartUrl = appendToUrl(addToCartUrl, form);

                    // $(this).trigger('updateAddToCartFormData', form);

                    $.ajax({
                        url: addToCartUrl,
                        type: 'get',
                        context: this,
                        dataType: 'json',
                        success: function (data) {
                            if (data.error) {
                                toastr.remove();
                                toastr.error(data.message);
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                                // handlePostCartAdd(data, $container.data('refresh'));
                                try {
                                    var addToCartParam = data.addToCartGtag;
                                    var removeFromCartGtag = data.removeFromCartGtag;
                                    if (addToCartParam) {
                                        $container.attr('data-gtag-add-event', addToCartParam.event);
                                        $container.attr('data-gtag-add-param', addToCartParam.param);
                                        $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    }

                                    if (removeFromCartGtag) {
                                        $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    }
                                    // trigger add to cart event for braze
                                    // braze.handleAddRemoveEvent(data.addToCartGtag);
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.warn(e.message);
                                }
                                $('body').trigger('cart:update', data);
                                $('body').trigger('cart:addTOCartGTM', $container);
                            }
                            $.spinner().stop();
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                } else if (quantity === 1) {
                    // $(this).trigger('updateAddToCartFormData', form);

                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if (data.error) {
                                toastr.error(data.message);
                                $.spinner().stop();
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                                try {
                                    var addToCartParam = data.addToCartGtag;
                                    var removeFromCartGtag = data.removeFromCartGtag;
                                    if (addToCartParam) {
                                        $container.attr('data-gtag-add-event', addToCartParam.event);
                                        $container.attr('data-gtag-add-param', addToCartParam.param);
                                        $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    }

                                    if (removeFromCartGtag) {
                                        $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    }
                                    // trigger add to cart event for braze
                                    // braze.handleAddRemoveEvent(data.addToCartGtag);
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.warn(e.message);
                                }
                                $('body').trigger('cart:update', data);
                                $('body').trigger('cart:addTOCartGTM', $container);
                                $.spinner().stop();
                            }
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                }
            }
            );

        $(document).off('click', '.custom-add-to-cart').on('click', '.custom-add-to-cart', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $parent = $this.parent();

            $parent.find('.closing-add-to-cart').trigger('click');
        });
    },

    removeFromCartClosingSoon: function () {
        $(document)
            .on('click', '.closing-minus-to-cart', function (e) {
                e.stopImmediatePropagation();
                e.preventDefault();
                var removeCartUrl = $(this).data('remove-cart-url');
                var updateToCartUrl = $(this).data('update-cart-url');
                var $container = $(this).parents('.closing-soon__item_parent');
                var pid = $($container).find('.closing-soon__item').data('product-id');
                var uuid = $($container).find('.closing-soon__item').attr('data-lineitem-uuid');
                var quantity = parseInt($($container).find('.qtyIcon').text());
                var maxQuantity = $(this).attr('max');
                maxQuantity = parseInt(maxQuantity);
                quantity -= 1;
                $.spinner().start();
                var page = 'home';
                form = {
                    pid: pid,
                    quantity: quantity,
                    isMobile: isMobile,
                    page: page,
                    uuid: uuid
                };

                if (quantity < 1) {
                    updateToCartUrl = removeCartUrl;
                    delete form.quantity;
                }

                var form;

                updateToCartUrl = appendToUrl(updateToCartUrl, form);

                $.ajax({
                    url: updateToCartUrl,
                    type: 'get',
                    context: this,
                    dataType: 'json',
                    success: function (data) {
                        if (data.error) {
                            toastr.error(data.message);
                            $.spinner().stop();
                        } else {
                            // eslint-disable-next-line no-lonely-if
                            module.exports.addToCartUiMobile($container, data, pid, 'minus');
                            // handlePostCartAdd(data, $container.data('refresh'));
                            try {
                                var addToCartParam = data.addToCartGtag;
                                var removeFromCartGtag = data.removeFromCartGtag;
                                if (addToCartParam) {
                                    $container.attr('data-gtag-add-event', addToCartParam.event);
                                    $container.attr('data-gtag-add-param', addToCartParam.param);
                                    $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                }

                                if (removeFromCartGtag) {
                                    $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                }
                                // trigger add to cart event for braze
                                // braze.handleAddRemoveEvent(data.removeFromCartGtag);
                                $('body').trigger('GTM:removeCart', data);
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.warn(e.message);
                            }
                            $.spinner().stop();
                            $('body').trigger('cart:update', data);
                            // $('body').trigger('cart:addTOCartGTM', $container);
                            $('body').trigger('product:AddedToCartFromDetail');
                        }
                    },
                    error: function () {
                        removeAddToCartLoader($container);
                        $.spinner().stop();
                    }
                });
            }
            );
    },

    minusFromCart: function () {
        $(document)
            .off('click', '.in-cart button.minus-from-cart-btn, .miniCartWrapper .js-minus')
            .on('click', '.in-cart button.minus-from-cart-btn, .miniCartWrapper .js-minus', function () {
                var addToCartUrl;
                var quantity;
                var $container = $(this);
                var form;
                var pid = $(this).data('pid');
                var lineitemUuid = $(this).attr('data-lineitem-uuid');

                var $all = $('.product-detail[data-pid = ' + pid + ']');
                var $all2 = $('.item-block.product-detail[data-pid = ' + pid + ']');
                var $allMinCart = $('.cart-items[data-product-id = ' + pid + ']');

                var page = 'home';
                if ($('.page').attr('data-action') === 'Home-Show') {
                    page = 'Home';
                } else if ($('.page').attr('data-action') === 'Product-Show') {
                    page = 'L2';
                } else if ($('.page').attr('data-action') === 'Cart-Show') {
                    page = 'Cart';
                }


                if ($all.length) {
                    quantity = parseInt($all.find('.cart-quantity').val(), 10);
                } else if ($(this).closest('.popover.show').length) {
                    quantity = parseInt($(this).closest('.item-block').find('.cart-quantity').val(), 10);
                } else {
                    quantity = parseInt($('.campaing-detail-footer')
                        .find('.quantity-select')
                        .children('option:selected')
                        .val(), 10);
                }

                if (quantity > 1) {
                    quantity -= 1;
                } else {
                    quantity = 0;
                    $all.find('.prize-details-btn').hide();
                    $all.find('.add-to-cart-btn').addClass('removed').html(resources.removedToCartText);
                    $all.closest('#quickViewModal').find('.add-to-cart-btn').addClass('removed').html(resources.removedToCartText);
                    $all.find('.campaign-action').removeClass('in-cart');
                    $all.closest('#quickViewModal').find('.campaign-action').removeClass('in-cart');
                    setTimeout(function () {
                        $all.find('.added-to-cart.removed').removeClass('added-to-cart').removeClass('removed');
                        $all.closest('#quickViewModal').find('.added-to-cart.removed').removeClass('added-to-cart').removeClass('removed');
                        $all.find('.prize-details-btn').show();
                        $all.find('.add-to-cart-btn').html(resources.addToCartText);
                        $all.closest('#quickViewModal').find('.add-to-cart-btn').html(resources.addToCartText);
                    }, 1300);
                }

                form = {
                    pid: pid,
                    quantity: quantity,
                    uuid: lineitemUuid,
                    isMobile: isMobile,
                    page: page
                };

                addToCartUrl = getUpdateToCartUrl();
                addToCartUrl = addToCartUrl || $(this).parent().find('.cart-quantity').data('action');
                addToCartUrl = appendToUrl(addToCartUrl, form);

                $all.find('.cart-quantity').val(quantity);
                $all.closest('#quickViewModal').find('.cart-quantity').val(quantity);
                $(this).closest('.item-block').find('.cart-quantity').val(quantity);
                $all.find('.in-cart button.minus-from-cart-btn').trigger('updateAddToCartFormData', form);


                if (addToCartUrl && quantity >= 1) {
                    $.ajax({
                        url: addToCartUrl,
                        type: 'get',
                        context: this,
                        dataType: 'json',
                        success: function (data) {
                            // $(this).parent().find('.cart-quantity').val(quantity);
                            try {
                                var addToCartParam = data.addToCartGtag;
                                var removeFromCartGtag = data.removeFromCartGtag;
                                if (addToCartParam) {
                                    $all.find('.in-cart button.minus-from-cart-btn').attr('data-gtag-add-param', addToCartParam.param);
                                    $all.find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    $all.closest('#quickViewModal').find('.in-cart button.minus-from-cart-btn').attr('data-gtag-add-param', addToCartParam.param);
                                    $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                }

                                $('.newCart-indicator .cart-indicator-icon  span').text(data.numItems);
                                $('.cart-page span.minicart-quantity').text(data.numItems);
                                $('.newCart-indicator').addClass('animate');
                                setTimeout(function () {
                                    $('.newCart-indicator').removeClass('animate');
                                }, $animate);

                                if (removeFromCartGtag) {
                                    if (quantity < 1) {
                                        $all.find('.cart-quantity').attr('data-gtag-remove-event', 'remove_from_cart');
                                        $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_from_cart');
                                    } else {
                                        $all.find('.cart-quantity').attr('data-gtag-remove-event', 'remove_quantity');
                                        $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_quantity');
                                    }
                                    $all.find('.minus-from-cart-btn').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    $all.find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    $all.closest('#quickViewModal').find('.minus-from-cart-btn').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);

                                    // braze.handleAddRemoveEvent(removeFromCartGtag);
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.warn(e.message);
                            }
                            $('body').trigger('cart:update', data);
                            $('body').trigger('cart:removeFromCartGTM', $container);
                            $('body').trigger('product:AddedToCartFromDetail');
                        },
                        error: function () {
                        }
                    });
                } else {
                    var productID = $(this).data('pid');
                    var url = $(this).data('remove-action');
                    var uuid = $(this).attr('data-lineitem-uuid');
                    var urlParams = {
                        pid: productID,
                        uuid: uuid,
                        isMobile: isMobile,
                        page: page
                    };
                    url = appendToUrl(url, urlParams);


                    $('body > .modal-backdrop').remove();
                    if (page === 'Cart') {
                        $all.find('.jsRemoveCartItem').trigger('click');
                    } else if ($(this).closest('.popover.popover-bottom.show').length) {
                        $(this).closest('.cart-items').find('.jsRemoveCartItem').trigger('click');
                    } else {
                        $.ajax({
                            url: url,
                            type: 'get',
                            dataType: 'json',
                            success: function (data) {
                                if (data.basket.numItems === 0) {
                                    $('.newCart-indicator .cart-indicator-icon span').removeClass('show');
                                    $('.newCart-indicator .cart-indicator-icon span').text(0);
                                    $('.minicart .popover.popover-bottom').removeClass('show');
                                    $('.minicart .popover.popover-bottom').empty();
                                } else {
                                    $('.newCart-indicator .cart-indicator-icon  span').text(data.basket.numItems);
                                }

                                $('.newCart-indicator').addClass('animate');
                                setTimeout(function () {
                                    $('.newCart-indicator').removeClass('animate');
                                }, $animate);
                                try {
                                    var addToCartParam = data.addToCartGtag;
                                    var removeFromCartGtag = data.removeFromCartGtag;
                                    if (addToCartParam) {
                                        $all.find('button.minus-from-cart-btn').attr('data-gtag-add-param', addToCartParam.param);
                                        $all.find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                        $all.closest('#quickViewModal').find('button.minus-from-cart-btn').attr('data-gtag-add-param', addToCartParam.param);
                                        $(this).closest('.cart-items').find('button.minus-btn').attr('data-gtag-add-param', addToCartParam.param);
                                        $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                        $(this).closest('.cart-items').find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    }

                                    if (removeFromCartGtag) {
                                        if (quantity < 1) {
                                            $all.find('.cart-quantity').attr('data-gtag-remove-event', 'remove_from_cart');
                                            $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_from_cart');
                                            $(this).closest('.cart-items').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_from_cart');
                                            if (page === 'Cart') {
                                                $all2.remove();
                                            }
                                        } else {
                                            $all.find('.cart-quantity').attr('data-gtag-remove-event', 'remove_quantity');
                                            $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_quantity');
                                            $(this).closest('.cart-items').find('.cart-quantity').attr('data-gtag-remove-event', 'remove_quantity');
                                        }
                                        $all.find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                        $all.closest('#quickViewModal').find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                        $(this).closest('.cart-items').find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                        // braze.handleAddRemoveEvent(removeFromCartGtag);
                                    }
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.warn(e.message);
                                }

                                $('body').trigger('cart:update', data.basket);
                                $all.find('button.minus-from-cart-btn').attr('data-lineitem-uuid', '');
                                $all.closest('#quickViewModal').find('button.minus-from-cart-btn').attr('data-lineitem-uuid', '');
                                $(this).closest('.cart-items').find('button.minus-btn').attr('data-lineitem-uuid', '');
                                $('body').trigger('cart:removeFromCartGTM', $container);
                            },
                            error: function () {
                            }
                        });
                    }
                }
            });
    },

    addToCartUiMobile: function (e, data, pid, stat, isBundle) {
        var $all = $('.product-detail[data-pid = ' + pid + ']');
        var $this = $all.find('.add-to-cart-global');
        var $this2 = $all.closest('#quickViewModal').find('.add-to-cart-global');

        var $parent = $this.parent();
        var $parent2 = $this2.parent();

        // eslint-disable-next-line no-undef
        var addTocartBtnText = resources.addToCartText;
        // var addedTocartText = resources.Global$mobileAdded;
        // eslint-disable-next-line no-undef
        var addMoreText = resources.Global$mobileAddmoreSmall;
        var inputQty;
        var itemsCart;
        var numItems;
        if (data === undefined) {
            return false;
        }
        if ('cart' in data) {
            itemsCart = data.cart.items;
            numItems = data.cart.numItems;
        } else if ('basket' in data) {
            itemsCart = data.basket.items;
            numItems = data.basket.numItems;
        } else {
            itemsCart = data.items;
            numItems = data.numItems;
        }
        window.productItemCount = [];

        var $input = $parent.find('.cart-quantity');
        var $input2 = $parent2.find('.cart-quantity');
        var $newinput = $all.find('.js-qty-selector ul li.active').length > 0 ? $all.find('.js-qty-selector ul li.active a').data('value') : 1;

        $this.attr('data-lineitem-uuid', '');
        $this.parent().find('.add-to-cart-btn ').attr('data-lineitem-uuid', '');

        $this2.attr('data-lineitem-uuid', '');
        $this2.parent().find('.add-to-cart-btn ').attr('data-lineitem-uuid', '');
        var addedProductUUId;
        var isProductRemoved = true;

        for (var i = 0; itemsCart.length > i; i++) {
            var lineItem = itemsCart[i];
            var id = lineItem.id;
            var uuid = lineItem.UUID;
            window.productItemCount[id] = lineItem.quantity;
            if (id === pid) {
                inputQty = lineItem.quantity;
                $this.attr('data-lineitem-uuid', uuid);
                addedProductUUId = uuid;
                $this.parent().find('.minus-from-cart-btn').attr('data-lineitem-uuid', uuid);
                $this.parents('.product-detail').find('.js-new-add-to-cart-btn').attr('data-lineitem-uuid', uuid);
                isProductRemoved = false;
                $this2.attr('data-lineitem-uuid', uuid);
                $this2.parent().find('.minus-from-cart-btn').attr('data-lineitem-uuid', uuid);
                $all.find('.js-qty-text').text(inputQty);
                $this.closest('.js-cart-feature.product-detail').attr('data-lineitem-uuid', uuid);
            }
        }
        if (isMobile) {
            $('.newCart-indicator .cart-indicator-icon  span').text(numItems);
        }

        if (!isProductRemoved) {
            if (!$parent.hasClass('in-cart') || !$parent2.hasClass('in-cart')) {
                // $this.addClass('added').html(addedTocartText);
                $parent.parent().find('.campaign-image').addClass('addedtoCart');

                // $this2.addClass('added').html(addedTocartText);
                $parent2.parent().find('.campaign-image').addClass('addedtoCart');

                setTimeout(function () {
                    if (window.productItemCount[pid] !== undefined && window.productItemCount[pid] > 0) {
                        $parent.addClass('in-cart');
                        $parent2.addClass('in-cart');
                        $this.removeClass('added').html(addMoreText);
                        $this2.removeClass('added').html(addMoreText);
                    }
                    $parent.find('prize-details-btn').hide();
                    $parent.find('.prize-details-btn').hide();
                    $parent.parent().find('.campaign-image').removeClass('addedtoCart');

                    $parent2.find('prize-details-btn').hide();
                    $parent2.find('.prize-details-btn').hide();
                    $parent2.parent().find('.campaign-image').removeClass('addedtoCart');
                    $('.js-qty-text').attr('disabled', false);
                    $('.js-new-add-to-cart-btn').attr('disabled', false);
                    $('body').trigger('product:AddedToCartFromDetail');
                }, 1300);
            }
            $input.val(inputQty);
            $input2.val(inputQty);
        }

        this.addToCart(e);
        this.minusFromCart();

        if (stat != 'minus') {
            // Minicart UI updates based on adding of cart item
            var textToShow = isBundle ? $('.bundle-text').text() : parseInt($newinput).toFixed() + ' ' + $('.unit-text').text();
            if (isMobile) {
                $('.newCart-indicator .cart-indicator-icon span').removeClass('show');
                $('.mini-cart-tooltip').show().find('.js-minicart-qty-unit').text(textToShow);
            }
            if (isMobile) {
                setTimeout(function () {
                    $('.mini-cart-tooltip').hide();
                    $('.newCart-indicator .cart-indicator-icon span').addClass('show');
                    handlePostCartAdd(data, e.data('refresh'));
                }, 3000);
            } else {
                handlePostCartAdd(data, e.data('refresh'));
            }
        } else {
            handlePostCartAdd(data, e.data('refresh'));
        }

        // if the campaign is in closing section as well update the UI for closing soon section
        var $closingSoonElm = $('.closing-soon__item[data-product-id = ' + pid + ']');
        if (!isProductRemoved) {
            if ($closingSoonElm.length > 0) {
                $closingSoonElm.find('.closing-soon_cart-actions').addClass('firstTimeAdded');
                $closingSoonElm.find('.closing-soon_cart-actions .qtyIcon').text(inputQty);
                $closingSoonElm.attr('data-lineitem-uuid', addedProductUUId);
                setTimeout(function () {
                    if (window.productItemCount[pid] !== undefined && window.productItemCount[pid] > 0) {
                        $closingSoonElm.find('.closing-soon_cart-actions').removeClass('firstTimeAdded').addClass('addedToCart');
                    }
                }, 1300);
            }
        }

        // logic to check if the product has been removed
        if (isProductRemoved) {
            if ($closingSoonElm.length > 0) {
                $closingSoonElm.find('.closing-soon_cart-actions').removeClass('addedToCart');
                $closingSoonElm.find('.closing-soon_cart-actions .qtyIcon').text('0');
                $closingSoonElm.attr('data-lineitem-uuid', '');
            }
            $parent.removeClass('in-cart');
            $parent2.removeClass('in-cart');
            $this.removeClass('added').html(addTocartBtnText);
            $('body').trigger('product:AddedToCartFromDetail');
            $('body').removeClass('scroll-lock');
            $this.closest('.js-cart-feature.product-detail').attr('data-lineitem-uuid', '');
        }
    },

    productSizeUi: function () {
        $(document)
            .off('click', '.size-panel')
            .on('click', '.size-panel', function () {
                var $this = $(this);
                var $parent = $this.closest('.product-size-box');
                $this.siblings().removeClass('active');
                $this.addClass('active');
            });
    },

    removeFromCartHomepageMobile: function () {
        $(document).off('click', '.product-detail .js-deleteQty').on('click', '.product-detail .js-deleteQty', function (e) {
            var $container = $(this);
            var page = 'home';
            var $productContainer = $(this).closest('.product-detail');
            var productID = $($productContainer).data('pid');
            var url = $(this).attr('href');
            var uuid = $($productContainer).find('.add-to-cart-global').data('lineitem-uuid');
            var urlParams = {
                pid: productID,
                uuid: uuid,
                isMobile: isMobile,
                page: page
            };
            url = appendToUrl(url, urlParams);
            // addAddToCartLoader($container);

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    if (data.error) {
                        toastr.error(data.message);
                    } else {
                        $($productContainer).find('.add-to-cart-area').removeClass('in-cart');
                        $productContainer.find('.js-qty-text').text('1');
                        $('.newCart-indicator .cart-indicator-icon span').text(data.basket.numItems);
                        // if the campaign is in closing section as well update the UI for closing soon section
                        var $closingSoonElm = $('.closing-soon__item[data-product-id = ' + productID + ']');
                        if ($closingSoonElm.length > 0) {
                            $closingSoonElm.find('.closing-soon_cart-actions').removeClass('firstTimeAdded').removeClass('addedToCart');
                            $closingSoonElm.find('.closing-soon_cart-actions .qtyIcon').text(0);
                            $closingSoonElm.attr('data-lineitem-uuid', '');
                        }
                    }
                    try {
                        var removeFromCartGtag = data.removeFromCartGtag;
                        if (removeFromCartGtag) {
                            $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                        }
                        // trigger add to cart event for braze
                        // braze.handleAddRemoveEvent(data.removeFromCartGtag);
                        $('body').trigger('GTM:removeCart', data);
                        $('body').trigger('product:AddedToCartFromDetail');
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn(e.message);
                    }
                    // removeAddToCartLoader($container);
                },
                error: function () {
                    // removeAddToCartLoader($container);
                }
            });
        });
    },

    minusFromCartHomepageMobile: function () {
        $(document)
            .off('click', '.js-new-minus')
            .on('click', '.js-new-minus', function (e) {
                e.stopImmediatePropagation();
                e.preventDefault();
                var $this = $(this);
                if ($this.attr('disabled') === 'disabled') {
                    return;
                }
                $this.attr('disabled', true);
                var removeCartUrl = $this.data('remove-cart-url');
                var updateToCartUrl = $this.data('update-cart-url');
                var $container = $this.parents('.product-detail');
                var pid = $($container).data('pid');
                var uuid = $($container).attr('data-lineitem-uuid');
                var quantity = parseInt($($container).find('.js-qty-text').text());
                var maxQuantity = $this.attr('max');
                maxQuantity = parseInt(maxQuantity);
                quantity -= 1;
                $.spinner().start();
                var page = 'home';
                form = {
                    pid: pid,
                    quantity: quantity,
                    isMobile: isMobile,
                    page: page,
                    uuid: uuid
                };

                if (quantity < 1) {
                    updateToCartUrl = removeCartUrl;
                    delete form.quantity;
                }
                var form;

                updateToCartUrl = appendToUrl(updateToCartUrl, form);

                $.ajax({
                    url: updateToCartUrl,
                    type: 'get',
                    context: this,
                    dataType: 'json',
                    success: function (data) {
                        if (data.error) {
                            toastr.error(data.message);
                            $.spinner().stop();
                            $this.attr('disabled', false);
                        } else {
                        // eslint-disable-next-line no-lonely-if
                            module.exports.addToCartUiMobile($container, data, pid, 'minus');
                            // handlePostCartAdd(data, $container.data('refresh'));
                            try {
                                var addToCartParam = data.addToCartGtag;
                                var removeFromCartGtag = data.removeFromCartGtag;
                                if (addToCartParam) {
                                    $container.attr('data-gtag-add-event', addToCartParam.event);
                                    $container.attr('data-gtag-add-param', addToCartParam.param);
                                    $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                }

                                if (removeFromCartGtag) {
                                    $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                }
                                // trigger add to cart event for braze
                                // braze.handleAddRemoveEvent(data.removeFromCartGtag);
                                $('body').trigger('GTM:removeCart', data);
                            } catch (e) {
                            // eslint-disable-next-line no-console
                                console.warn(e.message);
                            }
                            $.spinner().stop();
                            $('body').trigger('cart:update', data);
                            // $('body').trigger('cart:addTOCartGTM', $container);
                            $this.attr('disabled', false);
                        }
                    },
                    error: function () {
                        removeAddToCartLoader($container);
                        $.spinner().stop();
                    }
                });
            }
            );
    },

    addToCartFromHomepageMobile: function () {
        $(document)
            .off('click', '.js-new-add-cart')
            .on('click', '.js-new-add-cart', function (e) {
                e.preventDefault();
                var $this = $(this);
                var addToCartUrl = $this.data('add-to-cart-url');
                var updateToCartUrl = $this.data('update-cart-url');
                var $container = $this.parents('.product-detail');
                var pid = $($container).data('pid');
                var uuid = $($container).attr('data-lineitem-uuid');
                var quantity = parseInt($($container).find('.js-qty-text').text(), 10);
                var maxQuantity = $this.attr('max');
                $('body').removeClass('scroll-lock');
                maxQuantity = parseInt(maxQuantity, 10);
                var page = 'home';
                $.spinner().start();
                quantity = quantity < maxQuantity ? quantity + 1 : maxQuantity;
                if (isNaN(quantity)) {
                    quantity = 1;
                }

                if (quantity > 1) {
                    addToCartUrl = updateToCartUrl;
                }

                var form;
                // addAddToCartLoader($container);
                var childProducts = [];
                $container.find('.product-detail').find('.bundle-item').each(function () {
                    childProducts.push({
                        pid: $(this).data('pid'),
                        quantity: quantity
                    });
                });

                childProducts = childProducts.length ? JSON.stringify(childProducts) : getChildProducts($container, quantity);
                form = {
                    pid: pid,
                    quantity: quantity,
                    childProducts: childProducts,
                    isMobile: isMobile,
                    page: page
                };
                if (quantity > 1) {
                    form.uuid = uuid;

                    addToCartUrl = appendToUrl(addToCartUrl, form);

                    // $(this).trigger('updateAddToCartFormData', form);

                    $.ajax({
                        url: addToCartUrl,
                        type: 'get',
                        context: this,
                        dataType: 'json',
                        success: function (data) {
                            if (data.error) {
                                toastr.error(data.message);
                                $.spinner().stop();
                            } else {
                            // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                                // handlePostCartAdd(data, $container.data('refresh'));
                                try {
                                    var addToCartParam = data.addToCartGtag;
                                    var removeFromCartGtag = data.removeFromCartGtag;
                                    if (addToCartParam) {
                                        $container.attr('data-gtag-add-event', addToCartParam.event);
                                        $container.attr('data-gtag-add-param', addToCartParam.param);
                                        $container.parent().find('.cart-quantity').attr('data-gtag-add-param', addToCartParam.param);
                                    }

                                    if (removeFromCartGtag) {
                                        $container.parent().find('.cart-quantity').attr('data-gtag-remove-param', removeFromCartGtag.param);
                                    }
                                // trigger add to cart event for braze
                                // braze.handleAddRemoveEvent(data.addToCartGtag);
                                } catch (e) {
                                // eslint-disable-next-line no-console
                                    console.warn(e.message);
                                }
                                $('body').trigger('cart:update', data);
                                $('body').trigger('cart:addTOCartGTM', $container);
                                $.spinner().stop();
                            }
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                } else if (quantity === 1) {
                    // $(this).trigger('updateAddToCartFormData', form);

                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if (data.error) {
                                toastr.error(data.message);
                                $.spinner().stop();
                            } else {
                            // eslint-disable-next-line no-lonely-if
                                module.exports.addToCartUiMobile($container, data, pid);
                                $.spinner().stop();
                            }
                        },
                        error: function () {
                            removeAddToCartLoader($container);
                            $.spinner().stop();
                        }
                    });
                }
            });
    },
    addPromoBundleToCart: function () {
        $(document).off('click', '.js-add-bundle-to-cart').on('click', '.js-add-bundle-to-cart', function (e) {
            e.preventDefault();
            var bundlePids = [];
            var $this = $(this);
            var $container = $this.parents('.closing-soon__item_parent');
            var $parent = $('.bundle-container');
            $parent.find('.bundleLineItem-Box').each(function () {
            // try to add products individually
                var pid = $(this).attr('data-product-id');
                if (pid) {
                    bundlePids.push(pid);
                }
            });

            if (bundlePids.length > 0) {
                $.spinner().start();
                var action = $('.bundleOfferSections').attr('data-action');
                $.ajax({
                    url: action,
                    type: 'post',
                    data: { pids: JSON.stringify(bundlePids) },
                    success: function (data) {
                        if (!data.error) {
                            bundlePids.forEach(function (pid) {
                                module.exports.addToCartUiMobile($container, data, pid, null, true);
                            });
                            if (data.gtags && data.gtags.length > 0) {
                                $(document).trigger('addBundleOffer:success', JSON.parse(data.gtags[0].param));
                            }
                            if (!isMobile) {
                                bundleAddedAnimation();
                            }
                            $.spinner().stop();
                        } else {
                            $.spinner().stop();
                        }
                    }
                });
            }
        });
    },

    init: function () {
        module.exports.minusFromCart();
        module.exports.productSizeUi();
        $(document).on('addToCartModule:open', function (e) {
            module.exports.addToCartHomepageMobile();
            module.exports.minusFromCartHomepageMobile();
            module.exports.addToCartFromHomepageMobile();
        });
        module.exports.addToCartClosingSoon();
        module.exports.removeFromCartClosingSoon();
        module.exports.minusFromCartHomepageMobile();
        module.exports.addToCartFromHomepageMobile();
        module.exports.addPromoBundleToCart();
    },

    selectBonusProduct: base.selectBonusProduct,
    removeBonusProduct: base.removeBonusProduct,
    enableBonusProductSelection: base.enableBonusProductSelection,
    showMoreBonusProducts: base.showMoreBonusProducts,
    addBonusProductsToCart: base.addBonusProductsToCart,

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    changeThemeColor: function (newColor) {
        $('meta[name=theme-color]').attr('content', newColor);
    }
};

module.exports.init();
