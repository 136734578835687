'use strict';

module.exports = function () {
    $('.radio-button-group .input-field').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $text = $this.data('type');
        $text = $.trim($text).toLowerCase();

        if ($this.hasClass('active')) {
            $this.removeClass('active');
            $this.parent().find('input').val('');
        } else {
            $this.addClass('active');
            $this.parent().find('input').val($text);
        }
        $(document).trigger('radioChange');
        $this.siblings().removeClass('active');
    });
};
