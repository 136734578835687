// @ts-nocheck
'use strict';
var Swiper = require('swiper');

$('body').on('live:soldoutLoaded', function() {
    const soldoutSlider = new Swiper.default('.soldout-swiper-init', {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 21,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 21,
        preloadImages: false,
        watchSlidesVisibility: true,
        freeMode: true,
        pagination: {
            dynamicBullets: true,
            el: ".soldout-swiper-init .swiper-pagination",
            clickable: true,
          },
        navigation: {
            nextEl: '.soldout-swiper-init .swiper-button-next',
            prevEl: '.soldout-swiper-init .swiper-button-prev',
        },
        // Enable lazy loading
    });
})
