/* eslint-disable new-cap */
'use strict';
import Swiper from 'swiper/bundle';

$(document).on('initQuantitySwiper:open', function (e, data) {

    var mySwipers = new Swiper(".swiper-quantity-init", {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 13,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        preloadImages: false,
        watchOverflow: true,
        initialSlide: data && data.initialSlide > 2 ? data.initialSlide - 2 : 0,
        navigation: {
            nextEl: ".new-quantity-dropper .swiper-button-next",
            prevEl: ".new-quantity-dropper .swiper-button-prev",
        },
    });
});