/* eslint-disable new-cap */
'use strict';
var Swiper = require('swiper');
var isMobile = !($(window).width() >= 768);
var sliderOffsetBefore = 15;
var sliderOffsetAfter = 15;
var spaceBetweenSliders = 20;
var slideperView = 'auto';
if (!isMobile) {
    sliderOffsetBefore = 0;
    sliderOffsetAfter = 0;
    slideperView = 4;
}

module.exports = function () {
    // eslint-disable-next-line no-unused-vars
    var CampSlider = new Swiper.default('.modesh-products .swiper-container', {
        slidesPerView: slideperView,
        slidesOffsetBefore: sliderOffsetBefore,
        slidesOffsetAfter: sliderOffsetAfter,
        spaceBetween: spaceBetweenSliders,
        loop: false,
        pagination: {
            dynamicBullets: true,
            el: '.modesh-products .swiper-pagination',
        },
    });
};
