/* eslint-disable no-unused-vars */
'use strict';

import Swiper from 'swiper/bundle';
var isMobile = !($(window).width() >= 768);

$('body').on('live:winnerLoaded', function (e) {
    let mySwiperss = [];
    if(!isMobile) {

    $('.scrollable-prize-winner').each(function(index) {
        const $this = $(this);
        $this.addClass('swiperInit');
        $this.find(".swiper-container").addClass("instance-" + index);
        $this.find(".swiper-pagination").addClass("pagination-" + index);
        $this.find(".swiper-button-next").addClass("button-next-" + index);
        $this.find(".swiper-button-prev").addClass("button-prev-" + index);
        mySwiperss[index] = new Swiper(".instance-" + index, {
            slidesPerView: 1,
            direction: 'horizontal',
            spaceBetween: 30,
            mousewheel: false,
            pagination: {
                el: '.pagination-' + index,
                dynamicBullets: true,
            },
            navigation: {
                nextEl: ".button-next-" + index,
                prevEl: ".button-prev-" + index,
              },
        });
    });

    setTimeout(function () {
        for (const slider of mySwiperss) {
            slider.update();
        }
    }, 500);

} else {
    $('.scrollable-prize-winner').each(function(index) {
        const $this = $(this);
        $this.addClass('swiperInit');
        $this.find(".swiper-container").addClass("instance-" + index);
        $this.find(".swiper-pagination").addClass("pagination-" + index);
        mySwiperss[index] = new Swiper(".instance-" + index, {
            slidesPerView: 1,
            direction: 'horizontal',
            spaceBetween: 30,
            mousewheel: false,
            pagination: {
                el: '.pagination-' + index,
                dynamicBullets: true,
            }
        });
    });

    setTimeout(function () {
        for (const slider of mySwiperss) {
            slider.update();
        }
    }, 500);
}
});


$(document).on('winner:reTrigger', function (e) {
    let mySwiperss = [];
    $('.scrollable-prize-winner:not(".swiperInit")').each(function(index) {
        const $this = $(this);
        $this.addClass('swiperInit');
        $this.find(".swiper-container").addClass("instance-" + index);
        $this.find(".swiper-pagination").addClass("pagination-" + index);
        mySwiperss[index] = new Swiper(".instance-" + index, {
            slidesPerView: 1,
            direction: 'horizontal',
            spaceBetween: 30,
            mousewheel: false,
            pagination: {
                el: '.pagination-' + index,
                dynamicBullets: true,
            },
        });
    });

    setTimeout(function () {
        for (const slider of mySwiperss) {
            slider.update();
        }
    }, 50);
});
