/* eslint-disable no-lonely-if */
/* eslint-disable no-alert */
'use strict';

var toastr = require('toastr');
var isMobile = !($(window).width() >= 768);

module.exports = function (payload, message) {
    toastr.remove();
    if (isMobile) {
        if (payload && payload.success) {
            alert(message);
        } else if (payload && payload.error) {
            alert(message);
        } else if (payload && payload.fields) {
            $.each(payload.fields, function (key, value) {
                alert(value);
            });
        } else {
            alert(payload);
        }
    } else {
        if (payload && payload.success) {
            toastr.success(message);
        } else if (payload && payload.error) {
            toastr.error(message);
        } else if (payload && payload.fields) {
            $.each(payload.fields, function (key, value) {
                toastr.error(value);
            });
        } else {
            toastr.error(payload);
        }
    }
};
