/* eslint-disable no-undef */
'use strict';
var CUSTOM_MOENGAGE = require('../moengage');
module.exports = {
    // showDropDown: function () {
    //     $(document).on('click', '.js-language-link', function () {
    //         $(document).trigger('globalDropdown:show', 'languageDropDown');
    //     });
    //     $(document).on('click', '.js-currency-link', function () {
    //         $(document).trigger('globalDropdown:show', 'currencyDropDown');
    //     });
    // },

    logout: function () {
        $('.profile-link.logout').on('click', function (e) {
            e.preventDefault();
            $(document).trigger('logout:success');
            if (typeof Moengage !== 'undefined' && Moengage) {
                CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.logout, null, $(this).attr('href'));
            } else {
                window.location.href = $(this).attr('href');
            }
        });
    }
};
