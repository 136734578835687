'use strict';
module.exports = function () {
    // Modify this function once backend add to cart action available
    $(document).on('click', '.js-banner-add-to-cart', function (e) {
        var pid = $(this).data('pid');
        if (pid) {
            $('.js-cart-feature.product-detail[data-pid="' + pid + '"]').find('.js-trigger-cart').trigger('click');
        }
    });
};
