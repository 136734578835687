'use strict';

var processInclude = require('base/util');


$(document).ready(function () {
    processInclude(require('./components/mobileMainCarousel'));
    processInclude(require('./components/updateCampaignsAjax'));
    processInclude(require('./components/experiencesSwiper'));
    processInclude(require('./components/newRegisterPopout'));
    processInclude(require('./components/timeLimited'));
    processInclude(require('./components/soldoutSwiper'));
    processInclude(require('./components/productSlider'));
    processInclude(require('./components/sellingFast'));
    processInclude(require('./components/featuredSection'));
    processInclude(require('./components/scrollableWinner'));
    processInclude(require('./components/mobileBanner'));
    processInclude(require('./login'));
    processInclude(require('./mainMobile'));
    processInclude(require('./winnerSliderDesktop'));
    processInclude(require('./components/quantitySwiper'));
});
