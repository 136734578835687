// @ts-nocheck
'use strict';
import Swiper from 'swiper/bundle';
var isMobile = !($(window).width() >= 768);

var mySwipers = new Swiper(".mobileMainCarousel", {
    direction: 'horizontal',
    slidesPerView: 1,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    preloadImages: false,
    watchOverflow: true,
    pagination: {
        el: '.mobileMainCarousel .swiper-pagination-new'
    },
});

$(document).on('live:initDial', function () {
    $('.miniSlider').each(function (index) {
        var $this = $(this);
        var $newClass = `newS${index}`;
        var $newClassP = `newS${index}-promotional`;
        var $parent = $this.parent();
        var launched = $parent.find('.js-promotional-message');
        launched.addClass($newClassP);
        $this.addClass($newClass);

        const newSlide = new Swiper(`.${$newClass}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            pagination: {
                el: `.${$newClass} .swiper-pagination`,
            },
        });

        const newSlides = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    })

    $('.js-promotional-upper-bar').each(function (index) {
        var $this = $(this);
        var $newClassP = `newS${index}-promotional-bar`;
        $this.addClass($newClassP);

        const newSlidess = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    })
    const scrollPrize = new Swiper('.scrollable-prize .swiper-container', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        freeMode: false,
        spaceBetween: isMobile ? 6 : 16,
        mousewheel: true,
        navigation: {
            nextEl: ".scrollable-prize .swiper-button-next",
            prevEl: ".scrollable-prize .swiper-button-prev",
        }
    });
})