// @ts-nocheck
'use strict';
var Swiper = require('swiper');

module.exports = function () {
    var mySwipers = new Swiper.default(".win-experiences", {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 12,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        // autoplay: {
        //     delay: 3000
        // },
        // If we need pagination
        pagination: {
            el: '.win-experiences .swiper-pagination'
        }
    });
};
