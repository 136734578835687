'use strict';
var Swiper = require('swiper');
module.exports = function () {
    $(document).on('livecampaign:loaded', function() {
        var sellingFast = new Swiper.default('.sellingFast-swiper-init', {
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 21,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 21,
            preloadImages: false,
            watchSlidesVisibility: true,
            freeMode: true
            // Enable lazy loading
        });
    });
};
