/* eslint-disable no-undef */
var isMobile = !($(window).width() >= 768);
module.exports = {
    init: function () {
        $(document).on('click', '.revealPassword', function () {
            var $this = $(this);
            var $parent = $this.parent();
            var $show = $this.data('show');
            var $hide = $this.data('hide');
            if (isMobile) {
                $parent.toggleClass('showPassword');
                if ($parent.hasClass('showPassword')) {
                    $parent.find('.form-control').attr('type', 'text');
                    $this.text($hide);
                } else {
                    $parent.find('.form-control').attr('type', 'password');
                    $this.text($show);
                }
            } else {
                $this.toggleClass('showPassword');

                if ($this.hasClass('showPassword')) {
                    $parent.find('.form-control').attr('type', 'text');
                } else {
                    $parent.find('.form-control').attr('type', 'password');
                }
            }
        });
    }
};
