'use strict';
var Swiper = require('swiper');

$('body').on('live:winnerLoaded', function (e) {
    var CampSlider = new Swiper.default('#winnerScroller .container', {
        slidesPerView: 'auto',
        spaceBetween: 23,
        slidesOffsetBefore: 29,
        loop: false,
        navigation: {
            nextEl: '#main-winner-next-button',
            prevEl: '#main-winner-prev-button',
        }
    });
});
