'use strict';

var jqXhr;

var isMobile = !($(window).width() >= 768);

// eslint-disable-next-line require-jsdoc
function ajaxCall(options) {
    return $.ajax(options).fail();
}

/**
 * Lazy load winners section
 */
function lazyLoadWinners() {
    if ($('.winners-body').length > 0) {
        var url = $('.winners-body').data('url');
        if (url !== null && typeof url !== 'undefined') {
            jqXhr = ajaxCall({
                url: url
            });
            jqXhr.done(function (data) {
                if (data.trim().length > 0) {
                    // do something
                    $('.winners-body').html(data);
                    $('body').trigger('live:winnerLoaded');

                    // updating count on storefront
                    if (parseInt($(document).find('#totalCount').val(), 0) > 25) {
                        $('body').find('#home-show-more').show();
                        $('body').find('#home-show-more #winner-campaign-count').html($(document).find('#totalCount').val());
                        $('body').find('.js-homepage-show').show();

                    } else {
                        $('body').find('#home-show-more').hide();
                        $('body').find('.js-homepage-show').hide();
                    }
                } else {
                    $('#winnerScroller').hide();
                }
            });
        }
    }
}

module.exports = {
    init: function () {
        var url;

        $(document).ready(function () {
            // load live campaigns on ajax
            url = $('.live-section').data('url');
            if (url !== null && typeof url !== 'undefined') {
                jqXhr = ajaxCall({
                    url: url
                });
                jqXhr.done(function (data) {
                    // do something
                    $('.live-section').html(data);
                    $('body').trigger('live:initDial');
                    $('body').trigger('live:initTimeLimited');
                    $('body').trigger('livecampaign:loaded');
                });
            }

            // load soldout on ajax
            if ($('.soldout-section').length > 0) {
                url = $('.soldout-section').data('url');
                if (url !== null && typeof url !== 'undefined') {
                    jqXhr = ajaxCall({
                        url: url
                    });
                    jqXhr.done(function (data) {
                        if (data.trim().length > 0) {
                            // do something
                            var $html = $(data);

                            if (isMobile) {
                                if ($html.find('.campaign-box').length > 0) {
                                    $('.newSoldOut-section').show();
                                }
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if ($html.find('.swiper-slide').length > 0) {
                                    $('.soldout-campaign-area').show();
                                }
                            }
                            $('.soldout-section').html(data);
                            $('body').trigger('live:soldoutLoaded');
                            if (!isMobile) {
                                $(document).trigger('reInit');
                            }
                        } else {
                            $('.soldout-section').hide();
                        }
                    });
                }
            }

            // load Winner on ajax
            // lazyLoadWinners(); // registered function called on load
        });

        $(document).on('reloadLiveCampaigns', function (e, data) {
            var urlWithSort = data.url;
            if (urlWithSort !== null && typeof urlWithSort !== 'undefined') {
                jqXhr = ajaxCall({
                    url: urlWithSort
                });
                jqXhr.done(function (res) {
                    // do something
                    $('.live-section').html(res);
                    $('body').trigger('live:initDial');
                    $('body').trigger('live:initTimeLimited');
                });
            }
        });
    },
    homeShowMore: function () {
        $('#home-show-more').on('click', function () {
            var $this = $(this);
            var url = $this.data('url');
            var pageNo = $this.attr('data-pageno');
            var pageSize = $this.attr('data-pagesize');
            url += '&pagesize=' + pageSize + '&pageno=' + pageNo;
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    if (!isMobile) {
                        $('.winners-body .winner-container-body').append(data.renderedProducts);
                    } else {
                        $('.winners-body .container').append(data.renderedProducts);
                    }
                    // $this.text((pageSize * pageNo) + ' / ' + data.total);
                    $(document).trigger('winner:reTrigger');

                    if (data.lastPage || (data.total <= (pageSize * pageNo))) {
                        $this.css('display', 'none');
                    } else {
                        $this.attr('data-pageno', data.pageNo);
                    }
                    $.spinner().stop();
                },
                error: function (response) {
                    $.spinner().stop();
                }
            });
        });
    },
    lazyLoadWinners: lazyLoadWinners
};
