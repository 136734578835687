var easytimer = require('easytimer.js');

module.exports = {
    padWithZeroes: function (num, size) {
        var s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    },
    newCountdown: function (detail) {
        var parentDiv = $('.js-time-limited-enabled');
        var closedLabel = resources.closedLabel;
        var detailPage = $(detail);
   
        if (detailPage.length) {
            var $this = detailPage;
            var timeRem = $this.find('.limited-campaign-badge, .timer-enabled-time').find('.time-remaining');
            var closingLabel = $this.find('.limited-campaign-badge, .timer-enabled-time').find('.closingIn');
            var timer = new easytimer.Timer();
            var timeSeconds = timeRem.data('seconds');
            var campaignDesktop = $this.closest('.desktopCampaignBox');
            var wishList = $this.closest('.wishlist-item');
            var activeTickets = $this.closest('.lower-ticket');
            var campaignMobile = $this.closest('.mobileCampaignBox');

            timer.start({
                countdown: true,
                startValues: {
                    seconds: timeSeconds
                }
            });
            $this.find('.time-remaining').html(timer.getTimeValues().toString(['hours', 'minutes', 'seconds', 'secondTenths']));
            timer.addEventListener('secondsUpdated', function () {
                var hours = timer.getTotalTimeValues().hours;
                var minutes = timer.getTimeValues().minutes;
                var seconds = timer.getTimeValues().seconds;
                timeRem.addClass('show');


                if (wishList.length || activeTickets.length || $('.ticket-info-sub-block').length) {
                    timeRem.html(module.exports.padWithZeroes(hours, 2) + ' : ' + module.exports.padWithZeroes(minutes, 2) + ' : ' + module.exports.padWithZeroes(seconds, 2));
                } else {
                    timeRem.html(module.exports.padWithZeroes(hours, 2) + ' : ' + module.exports.padWithZeroes(minutes, 2) + ' : ' + module.exports.padWithZeroes(seconds, 2));
                    if (hours <= 8) {
                        timeRem.closest('.limited-campaign-badge').addClass('red');
                        timeRem.closest('.timer-enabled-time').addClass('red');
                    }
                    // timeRem.html('<div>' + module.exports.padWithZeroes(hours, 2) + ' <span>' + hourLabel + '</span></div> : <div>' + module.exports.padWithZeroes(minutes, 2) + ' <span>' + minLabel + '</span></div> : <div>' + module.exports.padWithZeroes(seconds, 2) + ' <span>' + secdLabel + '</span></div>');
                }
            });
            timer.addEventListener('targetAchieved', function () {
                timeRem.remove();
                closingLabel.html(closedLabel);
                closingLabel.addClass('finished');
                if (wishList.length || activeTickets.length) {
                    wishList.find('.addtocart-button').hide();
                    wishList.find('.add-to-cart').hide();
                    activeTickets.find('.buy-more-btn').hide();
                    $this.addClass('soldout');
                } else if (campaignDesktop.length || campaignMobile.length) {
                    campaignMobile.find('.campaign-action').removeClass('in-cart');
                    campaignMobile.find('.add-to-cart-btn').addClass('is-disabled');
                    campaignMobile.addClass('sold-out');
                    campaignMobile.find('.in-cart').removeClass('in-cart');


                    campaignDesktop.find('.campaign-action').removeClass('in-cart');
                    campaignDesktop.find('.add-to-cart-btn').addClass('is-disabled');
                    campaignDesktop.addClass('sold-out');
                    campaignDesktop.find('.in-cart').removeClass('in-cart');

                    $this.addClass('soldout');

                    if ($(window).width() > 767) {
                        campaignDesktop.find('.state-badge.sold-out').show();
                    }
                }
            });

            if (!timer.isRunning()) {
                timeRem.remove();
                closingLabel.html(closedLabel);
                closingLabel.addClass('finished');
                if (wishList.length || activeTickets.length) {
                    wishList.find('.addtocart-button').hide();
                    wishList.find('.add-to-cart').hide();
                    activeTickets.find('.buy-more-btn').hide();
                    $this.addClass('soldout');
                } else if (campaignDesktop.length || campaignMobile.length) {
                    campaignMobile.find('.campaign-action').removeClass('in-cart');
                    campaignMobile.find('.add-to-cart-btn').addClass('is-disabled');
                    campaignMobile.addClass('sold-out');
                    campaignMobile.find('.in-cart').removeClass('in-cart');


                    campaignDesktop.find('.campaign-action').removeClass('in-cart');
                    campaignDesktop.find('.add-to-cart-btn').addClass('is-disabled');
                    campaignDesktop.addClass('sold-out');
                    campaignDesktop.find('.in-cart').removeClass('in-cart');

                    $this.addClass('soldout');
                    if ($(window).width() > 767) {
                        campaignDesktop.find('.state-badge.sold-out').show();
                    }
                }
            }

        } else {
            parentDiv.each(function () {
                var $this = $(this);
                var timeRem = $this.find('.time-remaining');
                var closingLabel = $this.find('.closingIn');
                var timer = new easytimer.Timer();
                var timeSeconds = timeRem.data('seconds');
                var campaignDesktop = $this.closest('.desktopCampaignBox');
                var wishList = $this.closest('.wishlist-item');
                var activeTickets = $this.closest('.lower-ticket');
                var campaignMobile = $this.closest('.mobileCampaignBox');
                if (!timeRem.hasClass('show')) {
                    timer.start({
                        countdown: true,
                        startValues: {
                            seconds: timeSeconds
                        }
                    });
                    $this.find('.time-remaining').html(timer.getTimeValues().toString(['hours', 'minutes', 'seconds', 'secondTenths']));
                    timer.addEventListener('secondsUpdated', function () {
                        var hours = timer.getTotalTimeValues().hours;
                        var minutes = timer.getTimeValues().minutes;
                        var seconds = timer.getTimeValues().seconds;
                        timeRem.addClass('show');
                        if (wishList.length || activeTickets.length || $('.ticket-info-sub-block').length) {
                            timeRem.html(module.exports.padWithZeroes(hours, 2) + ':' + module.exports.padWithZeroes(minutes, 2) + ':' + module.exports.padWithZeroes(seconds, 2));
                        } else {
                            timeRem.html(module.exports.padWithZeroes(hours, 2) + ':' + module.exports.padWithZeroes(minutes, 2) + ':' + module.exports.padWithZeroes(seconds, 2));
                            if (hours <= 8) {
                                timeRem.closest('.limited-campaign-badge').addClass('red');
                                timeRem.closest('.timer-enabled-time').addClass('red');
                                timeRem.closest('.idealz-credit-campaign-box-upper-bar.isTimelimited').addClass('red');
                                timeRem.closest('.recommended-timer').addClass('red');
                            }
                        // timeRem.html('<div>' + module.exports.padWithZeroes(hours, 2) + ' <span>' + hourLabel + '</span></div> : <div>' + module.exports.padWithZeroes(minutes, 2) + ' <span>' + minLabel + '</span></div> : <div>' + module.exports.padWithZeroes(seconds, 2) + ' <span>' + secdLabel + '</span></div>');
                        }
                    });

                    timer.addEventListener('targetAchieved', function () {
                        timeRem.remove();
                        closingLabel.html(closedLabel);
                        closingLabel.addClass('finished');

                        if (wishList.length || activeTickets.length) {
                            wishList.find('.addtocart-button').hide();
                            wishList.find('.global-btn').addClass('is-disabled');
                            wishList.find('.quickview').addClass('is-disabled');
                            wishList.find('.add-to-cart').hide();
                            activeTickets.find('.buy-more-btn').hide();
                            $this.addClass('soldout');
                        } else if (campaignDesktop.length || campaignMobile.length) {
                            campaignMobile.find('.campaign-action').removeClass('in-cart');
                            campaignMobile.find('.add-to-cart-btn').addClass('is-disabled');
                            campaignMobile.addClass('sold-out');
                            campaignMobile.find('.in-cart').removeClass('in-cart');

                            $this.addClass('soldout');
                        }

                        if ($('.idealz-credit-campaign-box.product-detail').length > 0) {
                            $this.closest('.idealz-credit-campaign-box.product-detail').remove();
                        }

                        if ($('.recommended-timer').length > 0) {
                            $this.closest('.bundleLineItem-Box').remove();
                        }
                    });

                    if (!timer.isRunning()) {
                        timeRem.remove();
                        closingLabel.html(closedLabel);
                        closingLabel.addClass('finished');
                        $('.quickViewBox .close').trigger('click');
                        if (wishList.length || activeTickets.length) {
                            wishList.find('.addtocart-button').hide();
                            wishList.find('.add-to-cart').hide();
                            wishList.find('.global-btn').addClass('is-disabled');
                            wishList.find('.quickview').addClass('is-disabled');
                            activeTickets.find('.buy-more-btn').hide();
                            $this.addClass('soldout');
                        } else if (campaignDesktop.length || campaignMobile.length) {
                            campaignMobile.find('.campaign-action').removeClass('in-cart');
                            campaignMobile.find('.add-to-cart-btn').addClass('is-disabled');
                            campaignMobile.addClass('sold-out');
                            campaignMobile.find('.in-cart').removeClass('in-cart');

                            $this.addClass('soldout');

                        }
                    }
                }
            });
        }
    },
    init: function () {
        $('body').on('live:initTimeLimited', function (e) {
            module.exports.newCountdown();
        });
    }
};
